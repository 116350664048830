@media (max-width: 1400px) {
  .provedores-custom {
    margin-top: 20px!important;
  }
}

.card-situacao {
  width: 327px;
  height: 350px;
  border: 20px solid var(--primary);
  border-radius: 20px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.16);
  background-color: var(--primary);
  margin-left: 20px;

  .card-situacao-body {
    font-family: var(--font-family);
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "img situacao-title"
      "meus-dados meus-dados"
      "situacao-cadastro situacao-cadastro"
      "status-pagamento status-pagamento"
      "situacao situacao";
    background-color: white;
    border-radius: 16px;
    align-items: center;
    justify-items: center;
    height: 100%;
    padding: 16px 16px 60px 16px;

    img {
      grid-area: img;
      width: 56px;
      height: 40px;
      justify-self: right;
      margin-right: 16px;
    }

    .situacao-title {
      grid-area: situacao-title;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 110%;
      color: var(--primary);
    }

    .meus-dados {
      grid-area: meus-dados;
      width: 100%;
      height: 39px;
      background: var(--secondary);
      color: white;
      font-weight: 600;
      font-size: 14px;
    }

    .situacao-cadastro {
      grid-area: situacao-cadastro;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;

      span {
        color: var(--success);
      }
    }

    .status-pagamento {
      grid-area: status-pagamento;
      width: 100%;
      height: 39px;
      background: var(--secondary);
      color: white;
      font-weight: 600;
      font-size: 14px;
    }

    .situacao {
      grid-area: situacao;
      color: var(--success);
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }
  }
}

@media (max-width: 1700px) {
  .card-situacao {
    margin-left: 164px;
  }

  .card-situacao-custom-width {
    width: 40% !important;
  }
}

@media (max-width: 1200px) {
  .card-situacao {
    margin-left: inherit;
  }

  .card-situacao-custom-width {
    width: 50% !important;
  }
}
