:root {
  --primary: #4068c8;
  --secondary: #289ee0;
  --secondary-light: #81caf3;
  --light: #f5f5f5;
  --dark: #353535;
  --dark-light: #c4c4c4;
  --success: #00d17e;
  --warning: #febd59;
  --danger: #ff7461;
  --font-family: "Poppins-Bold";
  --gray: #aaaaaa;
}
