.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  color: #3f68c8;
  margin-right: 15px;
  border-radius: 7px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  color: #3f68c8;
  margin-left: 15px;
  border-radius: 7px;
}

.pagination > li:nth-child(2) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: auto;
}

.pagination > li:nth-last-child(2){
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
}



.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #fff;
  cursor: not-allowed;
  background-color: #3f68c8;
  border-color: #3f68c8;
  font-weight: 700;
}

.pagination > .active {
  border-color: #3f68c8;
  background-color: #3f68c8;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #3f68c8;
  cursor: default;
  background-color: #ffffff;
  border-color: #3f68c8;
  padding: 9px 18px;
  border-radius: 10px;
  margin: 1px;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 10px 20px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #ffffff;
  text-decoration: none;
  background-color: #3f68c8;
  border: 1px solid #3f68c8;
  font-weight: 700;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  cursor: pointer;
  background-color: #3f68c8;
  color: #ffffff;
}

.__user-status {
  justify-content: flex-start !important
}

.user-list-pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 0;
  margin: 12px;

  .user-list-pagination-item {
    flex: 1 0 100%;
    p {
      font-family: 'Poppins-SemiBold';
    }
    @media (min-width: 414px) {
      flex: 1 0 45%;

      &:last-of-type {
        text-align: right;
      }
    }
  }
}
