.materiais-servicos-container {
    margin: 0 30px 30px;
    font-family: Arial, Helvetica, sans-serif!important;

    .card-container {
        width: 100%!important;

        .card-body {
            padding: 10px 0;
        }

        .subtitle-form {
            padding: 25px 40px;
            background-color: #dadada;
            margin-top: -10px;
            font-weight: bold!important;
            font-size: 16px;
        }

        .created-form {
            display: flex!important;
            flex-direction: column!important;
            align-items: flex-end!important;

            .without-border {
                padding: 0px 40px!important;
                border-bottom: 0px!important
            }

            .input-group-container {
                width: 100%;
                display: flex;
                align-items: center;
                padding: 20px 40px;
                border-bottom: 1px solid #D1D1D1;

                label {
                    font-weight: bold!important;
                    min-width: 150px!important;
                    max-width: 150px!important;
                }

                input,textarea {
                    border: 0;
                    margin-right: 0!important;
                }

                .input-group-span {
                    width: 100%;
                    position: relative;

                    input,textarea {
                        width: -webkit-fill-available!important;
                    }

                    span:nth-last-child(1) {
                        white-space: nowrap;
                        position: absolute;
                        bottom: -21px;
                        left: 30px;
                        font-size: 12px;
                        color: red;
                    }
                }
            }

            & div:nth-last-child(2) {
                border-bottom: unset!important;
            }
        }
    }

    .input-list-wrap {
        width: -webkit-fill-available;
        position: relative;
        display: flex;

        input,textarea {
            max-width: -webkit-fill-available!important;
            margin-left: 30px!important;
        }

        input,textarea:disabled {
            opacity: 1!important;
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14)!important;
        }

        .list-item.d-inline-flex {
            position: absolute;
            top: 10px;
            left: 48px;
            display: flex;
        }

        .list-item.d-inline-flex > span {
            width: 386px;
            max-width: fit-content;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .global-btn {
        margin-top: 20px!important;
        margin-right: 40px;
        max-width: 130px !important;
    }

    .btnVoltar {
        margin-top: 20px !important;
        max-width: 130px !important;
    
        svg {
          margin-right: 5px;
        }
    }

    .enviar-btn {
        background-color: #3467c6;
        width: 100%;
        border: 1px solid #3467c6;
        padding: 12px;
        text-align: center;
        color: #ffffff;
        font-size: 13px;
        font-weight: 400;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14);
        border-radius: 10px;
        outline: 0;
        margin-left: 10px!important;

        &:hover,
        :focus,
        :active {
          background-color: #01b8ca;
          border: 1px solid #01b8ca;
          outline: 0;
        }

        &:active {
          background-color: #28285b;
          border: 1px solid #28285b;
        }
      }

    .form-info {
        margin: 10px 10px -20px 40px;
        display: flex;

        & > div {
            padding-right: 10px;
        }

        label {
            font-weight: bold;
            color: #3467c6;
        }

        p {
            font-size: 12px;
        }
    }

    .upload-form {
        margin-top: -8px;
        margin-bottom: 10px;

        .upload-content {
            margin: 20px 10px 20px 40px;
            display: flex;
            justify-content: space-between;

            & > div {
                padding-right: 10px;
            }
    
            label {
                font-size: 16px;
                font-weight: bold;
            }

            strong {
                font-family: Arial, Helvetica, sans-serif!important;
            }

            p {
                font-size: 12px;
            }
    
            .upload-button {
                height: 100px;
                min-width: 120px;
                padding-right: 0px;
                border: solid 1px;
                border-radius: 8px;
                background-color: #3467c6;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                color: #fff;
                padding: 10px;
                text-align: center;
                cursor: pointer;

                input {
                    display: none;
                }
            }
        }
    }

    .border-right {
        border-right: solid 1px #cacaca;
    }

}