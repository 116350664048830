.card-comprador-title {
  width: 200px;
  height: 40px;
  font-size: 30px;
  line-height: 45px;
  color: var(--primary);
  margin-left: 15px;
}

.card-comprador {
  width: 245px;
  height: 217px;
  background-color: white;
  border: 10px solid var(--primary);
  box-sizing: border-box;
  border-radius: 20px;
  margin-left: 15px;
  margin-top: 16px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.16);
}
