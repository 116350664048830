.login-container {
    background-color:#FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 15px;

    @media (min-width: 414px) {
        padding: 0px;
    }
}

.header-container {
    width: 100%;
    display: grid;
    grid-template-columns: 4fr 1.2fr;

    .public-app-item.item-rigth {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    h1 {
        margin-top: 0!important;
        font-size: 22px;
        font-weight: bold;   
        color: #323232;
    }

    p {
        font-size: 12px;

        a {
            color: #4068c8;
            text-align: right;
            text-decoration: none;
        }
    }
}



.form-container {
    max-width: 400px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}

.form-container-two-column {
    max-width: 400px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.form-footer-btn {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .group-input {
        flex: 1 0 100%;
        margin-right: 0px;
    
        @media (min-width: 414px) {
            flex: 1 0 45%;

            &:last-of-type {
                margin-right: 0px;
            }
        }
    }
}

.group-input {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    // flex: 1 0 100%;
    margin: 8px 0;

    &:nth-child(2n+2) {
        margin-right: 0px;
    }

    label {
        padding: 8px 0;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;   
        color: #99A3BA;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .global-input {
        border: 1px solid #CDD9ED;
        padding: 8px 16px;
        line-height: 25px;
        font-size: 14px;
        font-weight: 500;
        background-color: #fff;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14);
        border-radius: 10px;

        &:focus {
            outline: none;
            border-color: #275EFE;
        }
        
        &::placeholder {
            color: #a0a9ba;
            font-size: 14px;
            font-family: "Poppins-Regular";
        }

        &.error {
            border-color: #fa0e0e;
        }
    }

    .error-msg {
        padding: 3px 0;
        font-size: 12px;
        line-height: 21px;
        font-weight: 500;
        color: #fa0e0e;
    }
}

.error-list {
    background-color: #ffd5d5;
    border: 1px solid #fa0e0e;
    border-radius: 6px;
    padding: 15px;
}

.group-input-checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0;
    width: 100%;

    input[type="checkbox"] {
        opacity: 0;
    }

    .checkbox-label::before{
        content: "";
        display: inline-block;
        border-radius: 4px;
        height: 15px;
        width: 15px;
        border: 1px solid #323232;  
    }

    .checkbox-label::after {
        content: "";
        display: inline-block;
        height: 4px;
        width: 7px;
        border-left: 2px solid;
        border-bottom: 2px solid;
        transform: rotate(-45deg);
    }

    .checkbox-label {
        position: relative;
        margin-left: 10px;
        font-size: 12px;
        font-weight: 500;   
        color: #323232;
        cursor: pointer;
    }
    .checkbox-label::before,
    .checkbox-label::after {
        position: absolute;
    }
    /*Outer-box*/
    .checkbox-label::before {
        left: -20px;
    }
    /*Checkmark*/
    .checkbox-label::after {
        left: -16px;
        top: 5px;
    }

    /*Hide the checkmark by default*/
    input[type="checkbox"] + .checkbox-label::after {
        content: none;
    }
    /*Unhide the checkmark on the checked state*/
    input[type="checkbox"]:checked + .checkbox-label::after {
        content: "";
        color: #275EFE;
    }

    /*Adding focus styles on the outer-box of the fake checkbox*/
    input[type="checkbox"]:focus + .checkbox-label::before {
        outline:#275EFE auto 5px;
        border: 1px solid #275EFE;
    }
}

.global-btn {
    background-color: #3467c6;
    width: 100%;
    border: 1px solid #3467c6;
    padding: 12px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    // margin: 10px 0;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14);
    border-radius: 10px;

    &.baby-blue {
        background-color: #558CE5!important;
        border: 1px solid #558CE5;
    }

    &:hover, :focus, :active {
        background-color: #01b8ca;
        border: 1px solid #01b8ca;
        outline: 0;
    }

    &:active  {
        background-color: #28285b;
        border: 1px solid #28285b;
    }
}

.link-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 24px;

    p {
        font-size: 14px;
        line-height: 25px;
        font-weight: 500;   
        color: #99A3BA; 
    }

    a {
        font-size: 14px;
        line-height: 25px;
        font-weight: 500;   
        color: #4068c8;
        border-bottom: 1px solid transparent;
        text-decoration: none;

        &:hover {
            border-bottom: 1px solid #275EFE;
            cursor: pointer;
        }
    }
}

.footer-form-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .footer-form-item {
        flex: 1 0 40%;
        margin-right: 10px;

        &:last-of-type {
            margin-right: 0px;
        }

        .link-container {
            a {
                font-size: 12px;
            }
        }
    }
}