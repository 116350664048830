.dashboard {
  h1 {
    font-size: 30px;
  }

  strong {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: var(--gray);
  }
}

@media (max-width: 1200px) {
  .dashboard {
    div:first-child {
      flex-direction: column;
    }
  }
}

.local-bootstrap {
  margin: 1.5rem;
 

  .d-flex {
    display: flex !important;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .justify-content-center {
    justify-content: center !important;
  }

  .justify-content-between {
    justify-content: space-between !important;
  }

  .justify-content-end {
    justify-content: flex-end !important;
  }

  .align-items-center {
    align-items: center !important;
  }

  .align-items-end {
    align-items: end !important;
  }

  .mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .m-4 {
    margin: 1.5rem !important;
  }

  .mt-4 {
    margin-top: 1.5rem !important;
  }
  
  .mr-4 {
    margin-right: 1.5rem !important;
  }

  .ml-4 {
    margin-left: 1.5rem !important;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }

  // .g-0 {
  //   padding: 0 !important;
  //   margin: 0 !important;
  // }

  .mb-3 {
    margin-bottom: 1rem !important;
  }

  .w-100 {
    width: 100%;
  }

  .h-100 {
    height: 100%;
  }
}
