.submodules-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 20px;

  button {
    border: 2px #3f68c8 solid;
    border-radius: 10px;
    cursor: pointer;
    height: 90px;
    margin: 5px 10px;
    padding: 5px 25px;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);

    &:hover, 
    &.active {
      background: #3269CF;

      a {
        color: #fff!important;
      }

      img {
        filter: none;
      }
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        filter: invert(70%) sepia(100%) saturate(817%) hue-rotate(196deg)
          brightness(86%) contrast(82%);
        object-fit: scale-down;
        margin-right: 10px;
      }

      a {
        color: #3f68c8 !important;
        font-family: "Poppins-Medium";
        font-size: 16px;
        text-align: left;
        text-decoration: none;
        max-width: 165px;
      }

      @media (max-width: 1366px) {
        a {
          font-size: 14px;
        }
      }
    }
  }
}