.menu {
  background-color: #3f68c8;
  width: 294px;
  height: 100vh;
  top: 82px;
  position: absolute;
  left: -185px;
  box-shadow: 2px 0 10px 0 #557;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 1;

  @media (min-width: 414px) {
    top: 0;
    position: fixed;
    overflow-y: auto;
  }

  &::before {
    content: "";
    background-image: url("../../../assets/img/logotipo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    position: absolute;
    height: 185px;
    width: 180px;
    left: 40px;
    bottom: 0;
    z-index: -1;
  }

  &.slide {
    left: 0px;

    .icon-side-menu {
      text-align: end;
      padding: 10px;

      svg {
        transform: rotate(0deg);
        color: #ffffff;
        -webkit-transition: -webkit-transform 0.4s ease-in-out;
        transition: transform 0.4s ease-in-out;
      }
    }
  }

  .side-logo-container {
    height: 70px;
    margin: 24px auto;
    border-bottom: 1px solid #5679ca;
    padding-bottom: 20px;

    .side-logo {
      height: 100%;
      width: 100%;
      background-image: url("../../../assets/img/logo.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }
  }

  .icon-side-menu {
    text-align: end;
    padding: 10px;

    svg {
      transform: rotate(180deg);
      color: #ffffff;
      -webkit-transition: -webkit-transform 0.4s ease-in-out;
      transition: transform 0.4s ease-in-out;
    }
  }

  .menu-separator {
    border-bottom: 1px solid #528af2;
    margin: 20px 30px 20px 30px;
  }

  .menu-module {
    display: flex;
    padding: 8px 30px 8px 30px;
    transition: all 1s ease 500ms;

    a {
      color: #ffffff;
      font-size: 22px;
      // font-weight: 700;
      word-break: break-word;
      text-transform: uppercase;
      text-decoration: none;
      font-family: "Poppins-Regular";
    }

    p {
      color: #ffffff;
      font-size: 20px;
      // font-weight: 700;
      word-break: break-word;
      text-transform: uppercase;
      margin: 0;
    }

    img {
      margin-left: 15px;
    }

    .menu-submodule {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding-left: 0px;

      a {
        color: #ffffff;
        text-decoration: none;
        padding: 10px 0;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: "Poppins-Medium";

        &:hover {
          text-decoration: underline;
        }

        img {
          margin-right: 10px;
          max-width: 32px;
        }
      }
    }
  }

  .sub-module {
    padding: 8px 30px 8px 30px;
    align-items: center;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 60px 1fr;

    img{
      max-width: 35px;
    }

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #ffffff;
    }
  }
}
