.navbar-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 25px 35px 23px 10px;
    background-color: #ffffff;
    box-shadow: 10px 0px 16px rgba(0, 0, 0, 0.25);

    .navbar-item {
        margin-right: 15px;

        &:last-of-type {
            margin-right: 0;
            margin-top: 0;
        }

        p {
            font-size: 12px;
            color: #353535;
        }

        .dropdown-container {
            position: relative;
            z-index: 31;

            .dropdown-selected {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px;
                border-radius: 8px;
                text-align: center;

                svg {
                    margin-right: 10px;
                }

                img {
                    margin-left: 10px; 
                }

                p {
                    color: #353535;
                    font-size: 17px;
                    font-weight: 500;
                    margin: 0;
                    cursor: pointer;
                    text-transform: uppercase;
                }
            }
            .dropdown-options {
                padding: 0;
                border: 1px solid #3f68c6;
                list-style: none;
                border-radius: 8px;
                position: absolute;
                background-color: #ffffff;
                z-index: 100;

                &.hide {
                    display: none;
                }

                li {
                    border-bottom: 1px solid #3f68c6;
                    padding: 10px;
                    cursor: pointer;
                    margin: 5px;

                    &:last-of-type {
                        border-bottom: 0;
                    }

                    &:hover {
                        background-color: #f1f1f1;

                    }
                }
            }
        }
    }
}

#navbar-overlay {
    width: 100%;
    height: 100%;
    background-color: transparent!important;
    position: absolute;
    top: 0;
    z-index: 30;
}

#root {
    flex-direction: column;
}