.InputRadioGroup {
  display: inline-flex;
  width: 100%;
}
.InputRadio {
  display: inline-flex;
  width: 100%;
  align-items: center;    
  justify-content: flex-start;
  padding: 10px 15px;
  background-color: #d1d1d1;
  border-radius: 10px;
  height: 40px;
  margin: 8px 10px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);

  &.active {
      background-color: #fff;
  }


  &:first-child {
      margin-left: 0px;
  }

  &:last-child {
      margin-right: 0px;
  }

  input.checkIcon {
      display: none !important;
      
      &:checked + label.checkLabel:after {
        content: "";
        display: block;
        position: absolute;
        top: 14px;
        left: 5px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #FFF;
      }

      &:checked + label.checkLabel:before {
        background: #558CE5;
        border: 1px solid #558CE5;
      }
  }

  input.checkIcon + label {
      &:before {
          content: "";  
          display: block;
          width: 17px;
          height: 17px;
          margin-right: 14px;
          position: absolute;
          top: 9px;
          left: 0;
          border: 1px solid #aaa;
          background-color: #fff;
          border-radius: 50%;
      }
  }

  label.checkLabel {
    margin-left: 23px;
      min-width: 1px !important;
      width: auto !important;

      display: inline-block;
      cursor: pointer;
      position: relative;
      padding-left: 23px;
      margin: 0px;
      font-size: 13px;
  }
}