#private-app-content {
  @media (max-width: 414px) {
    margin-left: 0 !important;
  }
  .open-menu {
    margin-left: 170px;
  }
}
.private-app {
  display: grid;
}

.modal-mask {
  // display: none;
  position: fixed;
  z-index: 50;
  padding-top: 200px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  .modal-container {
    background-color: #fefefe;
    margin: auto;
    max-width: 540px;
    border-radius: 10px;

    .modal-header {
      background-color: #3467c6;
      text-align: center;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;

      h1 {
        color: #ffffff;
        font-size: 18px;
        font-family: "Poppins-Medium";
        margin: 15px;
        text-transform: none;
      }
    }

    .modal-body {
      padding: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      .btn-container {
        margin-right: 30px;
        padding: 0;

        .global-btn {
          background-color: #3467c6;
          width: 100%;
          border: 1px solid #3467c6;
          padding: 12px;
          text-align: center;
          color: #ffffff;
          font-size: 13px;
          font-weight: 400;
          box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14);
          border-radius: 10px;
          outline: 0;

          &:hover,
          :focus,
          :active {
            background-color: #01b8ca;
            border: 1px solid #01b8ca;
            outline: 0;
          }

          &:active {
            background-color: #28285b;
            border: 1px solid #28285b;
          }
        }

        .global-white-btn {
          background-color: #ffffff;
          border-radius: 10px;
          box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
          padding: 12px;
          font-size: 13px;
          font-weight: 400;
          border: none;
          color: #353535;
          cursor: pointer;
          outline: 0;
          width: 100%;

          &:hover {
            background-color: #3f68c8;
            color: #ffffff;
            outline: 0;
          }

          &:active {
            background-color: #28285b;
            color: #ffffff;
            outline: 0;
          }
        }
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
