.DropList {
    border-radius: 10px;
    margin-top: -5px;
    z-index: 2;

    .DropList-container {
        position: absolute;
        margin-left: 5px;
        min-width: 210px;
        max-width: 250px;

        ul {
            padding-left: 0!important;
            contain: content;
            max-height: 200px;
            overflow-y: auto;
            white-space: pre-wrap;
        }
    }

    &:not(.active) {
        border: none;
        width: auto;
        z-index: 1;

        .DropList-header {
            width: auto;
            background-color: unset;

            svg {
                margin-right: 0px;
            }

            p {
                display: none;
            }
        }

        .DropList-options {
            display: none;
        }
    }
    
    .DropList-header {
        display: inline-flex;
        align-self: center;
        background-color: #DCDCDC;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
        padding: 5px 10px;

        svg {
            margin-right: 10px;

            &:hover {
                cursor: pointer;
            }
        }

        p {
            margin-bottom: 0;
            font-size: 12px;
        }
    }

    .DropList-options {
        background-color: #F8F8F8;
        list-style: none;
        border: 1px solid #DCDCDC;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        li {
            padding: 5px 25px;
        }

        &.selectable-true {
            li {
                padding: 5px 25px 5px 30px!important;
                position: relative;
    
                &::after {
                    content: "";
                    position: absolute;
                    top: 6px;
                    left: 7px;
                    border: 2px solid;
                    padding: 7px 7px;
                    border-radius: 5px;
                }
            }
    
            li:hover {
                cursor: pointer;
                color: #4068c8;
                background-color: #DCDCDC;
            }
            
            li.selected {
                color: #4068c8;
                position: relative;
    
                &::after {
                    content: "√";
                    position: absolute;
                    top: 6px;
                    left: 7px;
                    font-size: 9.1px;
                    font-weight: bold;
                    border: 2px solid;
                    padding: 0.6px 3.9px;
                    border-radius: 5px;
                    line-height: 1.6!important;
                }
            }    
        }
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background-color: #DCDCDC;
        border-bottom-right-radius: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #808080cc;
        border-radius: 4px;
    }

}