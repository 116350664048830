.clientes-container {
    .card-container {

        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        

        .card-header h1 {
            margin: 10px 0 8px 5px!important;
        }

        .card-body {
            padding: 0!important;

            .list-container {
                box-shadow: none!important;

                .list-header {
                    border-top-left-radius: 0!important;
                    border-top-right-radius: 0!important;
                    background-color: #E3E3E3; 
                }
            }
        }

        
    }
    .user-list-search {
        width: 25% !important;
        .user-list-search-item {
            width: 100% !important;

            .search-input {
                width: 100% !important;
                max-width: 100% !important;
            }
        }
    }
}  
