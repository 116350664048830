body {
  margin: 0;
  padding: 0;
  font-family: "Poppins-Regular", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #F4F4F4;
}

@font-face{
  font-family: "Poppins-Thin";
  src: url("../src/assets/fonts/Poppins-Thin.ttf") format("truetype");
}
@font-face{
  font-family: "Poppins-SemiBold";
  src: url("../src/assets/fonts/Poppins-SemiBold.ttf") format("truetype")
}

@font-face{
  font-family: "Poppins-Regular";
  src: url("../src/assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face{
  font-family: "Poppins-Medium";
  src:  url("../src/assets/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face{
  font-family: "Poppins-Light";
  src:  url("../src/assets/fonts/Poppins-Light.ttf") format("truetype");
}

@font-face{
  font-family: "Poppins-Bold";
  src:  url("../src/assets/fonts/Poppins-Bold.ttf") format("truetype")
}


html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}


h1 {
  font-family: "Poppins-Bold";
}

h2 {
  font-family: "Poppins-SemiBold";
}

p {
  font-family: "Poppins-Regular";
}

a {
  font-family: "Poppins-Regular";
}