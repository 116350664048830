.buyer-container {
  background-color:#FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 15px;

  @media (min-width: 414px) {
      padding: 0px;
  }
}

.error-list {
  background-color: #ffd5d5;
  border: 1px solid #fa0e0e;
  border-radius: 6px;
  padding: 15px;
  max-width: 600px;
  margin: 15px;
}

.success-msg {
  background-color: #bffcbc;
  border: 1px solid #15fa00;
  border-radius: 6px;
  padding: 15px;
  max-width: 600px;
  margin: 15px;
}