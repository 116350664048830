.dashboard-container {
  margin-left: 20px;
  margin-right: 20px;

  .loading {
    display: none;
  }

  .loading-mask {
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.53);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 45px;
      color: #fff;
    }
  }

  h1 {
    color: #353535;
    font-size: 30px;
    text-transform: uppercase;
    margin: 30px 15px;
  }

  .module-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .module-item {
      flex: 1 0 100%;

      @media (min-width: 414px) {
        flex: 1 0 50%;
      }
    }
  }

  .gestaoAcessoContainer {
    margin-right: auto;
  }

  // user config
  .user-config-container {
    margin: 8px;
  }
  .card-container {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
    background-color: white;

    .card-header {
      background-color: #4068c8;
      padding: 15px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      h1 {
        color: #ffffff;
        font-size: 22px;
        font-family: "Poppins-Bold";
        margin: 25px 0 8px 30px;
      }
    }

    .card-body {
      padding: 45px;

      &.created-form {
        padding: 25px;
      }

      .form-container {
        max-width: 100%;

        .group-input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          width: 100%;

          .content-input {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }

          &.spaced-btw {
            justify-content: space-between;
          }

          &.created-form {
            &.gt {
              label {
                width: 250px;
              }
            }

            &.lt {
              label {
                width: 200px;
              }
            }

            label {
              width: 180px;
            }
            input {
              margin: 0 30px 0 30px;
            }
            button {
              margin: 0px;
              width: 80px;
              line-height: 20px;

              &.organismo-cert {
                margin-right: 30px;
              }
            }
          }

          label {
            font-size: 16px;
            font-weight: 600;
            color: #353535;
            min-width: 65px;
            width: 60px;
            margin-bottom: 0 !important;

            &.escopo-avaliacao {
              min-width: unset;
              width: unset;
            }

            @media (min-width: 414px) {
              min-width: 100px;
            }
          }

          .global-input {
            width: 100%;
            margin-left: 2px;

            &.small {
              width: 100px;
            }

            &::placeholder {
              color: #a0a9ba;
              font-size: 14px;
              font-family: "Poppins-Regular";
            }
          }

          &.radio {
            margin-bottom: 25px;
            label {
              min-width: 182px;
            }
            .inputs {
              border: 1px solid #cdd9ed;
              padding: 8px 16px;
              line-height: 25px;
              font-size: 14px;
              background-color: #fff;
              box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14);
              border-radius: 10px;
              min-width: 111px;
              margin-left: 20px;
              display: flex;
              justify-content: center;
              align-items: center;

              &:first-of-type {
                margin-left: 0;
              }

              input {
                position: relative;
                margin: 0 15px 0 0;

                &::placeholder {
                  color: #a0a9ba;
                  font-size: 14px;
                  font-family: "Poppins-Regular";
                }
              }

              &.white {
                background-color: #ffffff;
              }
              &.dark {
                background-color: #dddddd;
              }
            }
          }

          // switch
          .toggle-switch {
            position: relative;
            display: inline-block;
            border-radius: 10px;
            filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.14));
            width: 176px;
            height: 53px;
          }
          .toggle-switch input[type="checkbox"] {
            display: none;
          }
          .toggle-switch .switch {
            position: absolute;
            cursor: pointer;
            background-color: #ff7461;
            color: #ffffff;
            border-radius: 10px;
            text-align: center;
            padding: 16px 0;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 150px;
            transition: background-color 0.2s ease;
            justify-content: center;
          }
          .toggle-switch .switch::before {
            position: absolute;
            content: "";
            left: 7px;
            top: 11px;
            width: 30px;
            height: 30px;
            background-color: #ffffff;
            border-radius: 8px;
            transition: transform 0.3s ease;
          }
          .toggle-switch input[type="checkbox"]:checked + .switch::before {
            transform: translateX(117px);
            background-color: #ffffff;

            @media (min-width: 414px) {
              transform: translateX(112px);
              left: 0px;
            }
          }
          .toggle-switch input[type="checkbox"]:checked + .switch {
            background-color: #00d17e;
            text-align: start;
            padding-left: 10px;
            display: flex;
            justify-content: left;
          }
          // switch end
        }

        .global-btn {
          max-width: 100%;
          margin-left: auto;

          @media (min-width: 414px) {
            max-width: 182px;
            margin-top: 20px;
          }
        }
      }
    }
  }
  // user config end

  // user module
  .user-module-container {
    margin: 12px;
    max-width: 766px;
  }

  .user-module-header-container {
    padding: 20px;
    margin: 12px 0;
    border-radius: 10px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
    width: 50%;

    .user-module-header-content {
      display: flex;
      justify-content: right;
      align-items: center;

      label {
        font-weight: 700;
        font-size: 16px;
        margin-right: 15px;
      }

      .user-module-header-item {
        padding: 12px 24px;
        border-radius: 10px;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
        font-weight: 500;
        font-size: 14px;
        width: 100%;
        margin: auto;
      }
    }
  }
  // user module end

  // user list
  .user-list-container {
    margin: 0 8px 12px 8px;

    &.organismo-cert {
      .list-body {
        .list-item {
          margin-right: 10px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }

  .user-list-search {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 15px 15px;

    .user-list-search-item {
      flex: 1 0 100%;

      @media (min-width: 414px) {
        flex: 1 0 45%;
      }

      &:last-of-type {
        text-align: end;
        position: relative;
      }

      &.start {
        text-align: initial;
      }

      .global-white-btn {
        max-width: 190px;
        margin-right: auto;
      }
    }

    .global-white-btn {
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
      padding: 12px;
      border: none;
      color: #353535;
      cursor: pointer;
      outline: 0;
      width: 100%;
      margin-bottom: 12px;

      @media (min-width: 414px) {
        // max-width: 210px;
        margin-bottom: 0;
        text-align: center;
      }

      svg {
        margin-right: 10px;

        path {
          fill: #353535;
        }
      }

      &:hover {
        background-color: #3f68c8;
        color: #ffffff;
        outline: 0;

        svg {
          path {
            fill: #ffffff;
          }
        }
      }

      &:active {
        background-color: #28285b;
        color: #ffffff;
        outline: 0;

        svg {
          color: #ffffff;
        }
      }
    }

    .search-input {
      border: 1px solid #cdd9ed;
      padding: 8px 16px;
      line-height: 25px;
      font-size: 14px;
      font-weight: 500;
      background-color: #fff;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14);
      border-radius: 10px;
      width: 100%;
      margin-bottom: 12px;

      @media (min-width: 414px) {
        max-width: 250px;
        margin-bottom: 0;

        &.start {
          max-width: 550px;
        }
      }

      &:focus {
        outline: none;
        border-color: #275efe;
      }

      &::placeholder {
        color: #a0a9ba;
        font-size: 14px;
        font-family: "Poppins-Regular";
      }
    }

    .search-btn {
      border: none;
      position: absolute;
      right: 0;
      background: #3f68c8;
      height: 78%;
      min-width: 39px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      outline: 0;
      cursor: pointer;
      &.start {
        left: 540px;
      }
      @media (min-width: 414px) {
        height: 100%;
      }

      svg {
        color: #ffffff;
      }

      &:hover {
        background-color: #89cdd2;
        color: #ffffff;
        outline: 0;

        svg {
          color: #ffffff;
        }
      }

      &:active {
        background-color: #28285b;
        color: #ffffff;
        outline: 0;

        svg {
          color: #ffffff;
        }
      }
    }
  }

  .list-container {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
    background-color: white;
    .list {
      padding-bottom: 16px;
      font-weight: 400;

      .list-header {
        display: flex;
        justify-content: flex-start;
        padding: 15px;
        margin-bottom: 16px;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow-y: scroll;

        @media (min-width: 414px) {
          overflow-y: initial;
        }

        label {
          font-size: 16px;
          font-family: "Poppins-Medium";
          color: #353535;
          flex: 1 0 35%;
          margin-bottom: 0 !important;
          padding: 8px;

          @media (min-width: 414px) {
            flex: 1 0 15%;
          }

          &.space-list {
            &:first-of-type {
              flex: 1 0 75%;
              @media (width: 414px) {
                flex: 1 0 15%;
              }
            }
          }

          &:last-of-type {
            flex: 1 0 5%;
            text-align: end;
            padding-right: 15px;
          }
        }
      }
      .list-body {
        display: flex;
        justify-content: flex-start;
        padding: 12px 16px;
        overflow-y: scroll;

        @media (min-width: 414px) {
          overflow-y: initial;
        }

        .list-item {
          font-size: 14px;
          font-family: "Poppins-Regular";
          color: #353535;
          flex: 1 0 35%;
          word-break: break-word;

          &.space-list {
            &:first-of-type {
              flex: 1 0 75%;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              @media (max-width: 414px) {
                flex: 1 0 16%;
              }
            }
          }
          &.document-list {
            &:last-of-type {
              flex: 1 0 5%;
              text-align: end;
              padding: 0;
            }
          }
          @media (min-width: 414px) {
            flex: 1 0 16%;
          }

          a {
            color: #353535;
            margin-right: 10px;
            &:hover {
              svg {
                max-width: 32px;
                path {
                  fill: #3f68c8;
                }
              }
            }

            &:active {
              svg {
                max-width: 32px;
                path {
                  fill: #28285b;
                }
              }
            }
          }

          &:last-of-type {
            flex: 1 0 6%;
            text-align: end;
            padding-right: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
              outline: 0;
              background-color: #fff;
              border: none;

              &:hover {
                svg {
                  max-width: 32px;
                  path {
                    fill: #ff7461;
                  }
                }
              }

              &:active {
                svg {
                  max-width: 32px;
                  path {
                    fill: #983426;
                  }
                }
              }
            }
          }

          .narrow-icon {
            width: 20px;
            margin: 2px;
          }

          &.green {
            color: #00d178;
          }

          &.red {
            color: #ff7461;
          }

          .dropdown-options {
            padding: 0;
            border: 1px solid #d3d3d3;
            list-style: none;
            border-radius: 8px;
            position: absolute;
            background-color: #f8f8f8;
            box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
            min-width: 230px;
            z-index: 1;

            &::before {
              content: "\25BE \00a0 \00a0 \00a0 Ativos";
              padding: 5px;
              border-radius: 8px;
              display: block;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 0px;
              background-color: #dcdcdc;
            }

            &.hide {
              display: none;
            }

            li {
              padding: 5px 25px;
              margin: 5px 0;
              background-color: #f8f8f8;

              &:last-of-type {
                border-bottom: 0;
              }
            }
          }
        }
      }

      &.criticidade {
        .list-header label {
          flex: 0 0 17%;
        }

        .list-item {
          flex: 0 0 17%;

          a {
            margin-right: 0px;
          }

          button {
            outline: 0;
          }
        }
      }
    }
  }

  .user-list-pagination {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 0;
    margin: 12px;

    .user-list-pagination-item {
      flex: 1 0 100%;

      @media (min-width: 414px) {
        flex: 1 0 40%;

        &:last-of-type {
          flex: 1 0 60%;
        }
      }

      p {
        font-size: 14px;
        color: #353535;
      }

      .user-list-pagination-container {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: 414px) {
          justify-content: flex-end;
        }

        .btn-pagination {
          border: 1px solid #3f68c8;
          background-color: #ffffff;
          color: #3f68c8;
          border-radius: 10px;
          padding: 4px;
          outline: 0;

          @media (min-width: 414px) {
            padding: 10px;
          }

          &:hover {
            background-color: #3f68c8;
            color: #ffffff;
            outline: 0;
          }

          &:active {
            background-color: #28285b;
            color: #ffffff;
            outline: 0;
          }
        }
        .user-number {
          padding: 10px;
          background-color: #3f68c8;
          border-radius: 10px;
          margin: 0 10px;

          span {
            color: #ffffff;
            padding: 0 10px;

            &.active {
              color: #3f68c8;
              background-color: #ffffff;
              border-radius: 10px;
              padding: 8px 14px;
            }
          }
        }
      }
    }
  }
  // user list end

  // create criteria
  .btn-container {
    padding: 0 15px 20px;
    display: inline-flex;

    .global-white-btn {
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
      padding: 12px;
      border: none;
      color: #353535;
      cursor: pointer;
      outline: 0;
      width: 100%;
      margin-bottom: 12px;
      text-decoration: none;
      font-family: "Poppins-Medium";
      min-width: 63px;
      @media (min-width: 414px) {
        margin-bottom: 0;
        text-align: center;
      }

      svg {
        margin-right: 10px;
        path {
          fill: #353535;
        }
      }

      &:hover {
        background-color: #3f68c8;
        color: #ffffff;
        outline: 0;

        svg {
          path {
            fill: #ffffff;
          }
        }
      }

      &:active {
        background-color: #28285b;
        color: #ffffff;
        outline: 0;

        svg {
          color: #ffffff;
        }
      }
    }
  }
  // create criteria end

  // new criteria
  .back-container {
    max-width: 150px;
    margin-top: 20px;
  }
  // new criteria end

  // Manage access
  .user-list-btn-container {
    display: inline-flex;
    max-width: 400px;
    width: 100%;
    padding: 0 15px 20px;

    .global-btn {
      background-color: #3467c6;
      width: 100%;
      border: 1px solid #3467c6;
      padding: 12px;
      text-align: center;
      color: #ffffff;
      font-size: 13px;
      font-weight: 400;
      margin-right: 20px;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14);
      border-radius: 10px;
      outline: 0;

      &.baby-blue {
        background-color: #558ce5 !important;
        border: 1px solid #558ce5;
      }

      &:hover,
      :focus,
      :active {
        background-color: #01b8ca;
        border: 1px solid #01b8ca;
        outline: 0;
      }

      &:active {
        background-color: #28285b;
        border: 1px solid #28285b;
      }

      svg {
        margin-right: 10px;
      }

      img {
        max-width: 12px;
        margin-right: 8px;
      }
    }

    .global-white-btn {
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);
      padding: 12px;
      border: none;
      color: #353535;
      cursor: pointer;
      outline: 0;
      width: 100%;
      margin-right: 20px;
      font-weight: 600;
      svg {
        margin-right: 10px;

        path {
          fill: #353535;
        }
      }

      &:hover {
        background-color: #3f68c8;
        color: #ffffff;
        outline: 0;
        font-weight: 400;

        svg {
          path {
            fill: #ffffff;
          }
        }
      }

      &:active {
        background-color: #28285b;
        color: #ffffff;
        outline: 0;

        svg {
          color: #ffffff;
        }
      }

      img {
        max-width: 12px;
        margin-right: 8px;
      }
    }
  }
  // Manage access end

  // Gestao indoneidade
  .gestao-container {
    max-width: 540px;
    margin-right: auto;
  }

  .gestao-idoneidade-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .gestao-idoneidade-item {
      flex: 1 0 67%;

      &:last-of-type {
        flex: 1 0 32%;
        margin-right: 12px;
      }
    }
  }

  .gestao-list-container {
    max-width: 445px;
    margin-right: auto;
  }

  .tipo-informacao-container {
    max-width: 540px;
    margin-right: auto;

    .group-input label {
      min-width: 73px !important;
      width: 100%;
    }
  }

  .resultado-container {
    margin: auto;
    margin-top: 55px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.14);

    .list-resultado-header {
      background-color: #4068c8;
      padding: 20px 0 20px 32px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      h1 {
        color: #ffffff;
        font-size: 13px;
        font-family: "Poppins-Medium";
        margin: 0;
      }
    }

    .list-resultado-body {
      padding: 0 29px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;

      .list-resultado-item {
        flex: 1 0 15%;
        padding: 10px 0;

        &:last-of-type {
          flex: 1 0 85%;
        }

        img {
          max-width: 30px;
        }
      }
    }
  }
  // Gestao indoneidade end

  // criticidade
  .criticidade-form-container {
    max-width: 500px;
    margin-right: auto;
  }

  .filter-search {
    display: flex;
    justify-content: space-between;
  }

  .organismo-cert-form {
    display: flex;
    margin-bottom: 5px;
    min-width: 100px;
  }

  // toggle switch
  .switch-container {
    display: flex;

    .toggle-switch {
      position: relative;
      display: inline-block;
      width: 176px;
      height: 44px;
      border-radius: 10px;
      margin-bottom: 0 !important;
    }
    .toggle-switch input[type="checkbox"] {
      display: none;
    }
    .toggle-switch .switch {
      position: absolute;
      cursor: pointer;
      background-color: #ff7461;
      color: #ffffff;
      border-radius: 10px;
      text-align: center;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 150px;
      transition: background-color 0.2s ease;
      justify-content: center;
      
      & ::before {
        left: 7px;
      }

      p {
        margin: 14px !important;
      }
    }
    .toggle-switch .switch::before {
      position: absolute;
      content: "";
      left: 8px;
      top: 7px;
      width: 30px;
      height: 30px;
      background-color: #ffffff;
      border-radius: 10px;
      transition: transform 0.3s ease;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch::before {
      transform: translateX(105px);
      background-color: #ffffff;
    }
    .toggle-switch input[type="checkbox"]:checked + .switch {
      background-color: #00d17e;
      text-align: start;
      p {
        margin: 13px 0 0px 16px !important;
      }
    }
  }
  // switch end
}
