.public-app {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    height: 100vh;
    overflow: hidden;
}

.public-app-content {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1110px;
    min-height: 600px;
    width: 100%;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.14);
    border-radius: 30px;
    overflow: hidden;
    margin: auto;

    .public-app-item {
        flex: 1 0 100%;

        &:last-of-type {
            display: none;

            @media (min-width: 768px) {
                display: block;
            }
        }

        @media (min-width: 414px) {
            flex: 1 0 50%;
        }


        .bg-img  {
            height: 100%;
            width: 100%;
            background-image: url('../../src/assets/img/imagem.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }
    }
}