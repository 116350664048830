@media (max-width: 1700px) {
  .card-provedores {
    width: 796px !important;
  }
 
}

@media (max-width: 992px) {
  .card-provedores {
    margin-top: 16px;
  }
}

@media (max-width: 992px) {
  .card-provedores {
    width: 360px !important;

    .card-provedores-body:first-child {
      width: inherit !important;
    }
    .card-provedores-body:last-child {
      margin-top: 16px;
      width: 100% !important;
    }
  }
}

.card-provedores {
  width: 796px;
  border: 20px solid var(--primary);
  border-radius: 20px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.16);
  background-color: var(--primary);
  padding: 0 12px;

  .line {
    background-color: var(--primary);
    height: 1px;
  }

  .card-provedores-body:first-child {
    width: calc(60% - 16px);
  }
  .card-provedores-body:last-child {
    width: 40%;
    display: flex;
    padding: 16px;

    img {
      width: 23px;
      height: 23px;
    }

    .numero-provedor {
      font-weight: 500;
      font-size: 14px;
      color: var(--primary);
    }

    .provedores-data {
      height: 110px;

      .homologados-label {
        font-size: 12px;
        font-weight: 300;
      }
    }

    .blue {
      width: 60px;
      height: 30px;
      background-color: var(--primary);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }

    .provedor-count {
      width: 60px;
      height: 30px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      min-width: fit-content;
    }

    .green {
      background-color: var(--success);
    }

    .warning {
      background-color: var(--warning);
    }

    .red {
      background-color: var(--danger);
    }
  }

  .card-provedores-body {
    font-family: var(--font-family);
    background-color: white;
    border-radius: 16px;
    padding: 16px;
    height: 310px;

    .provedores-title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 110%;
      color: var(--primary);

      img {
        grid-area: img;
        width: 45px;
        height: 39px;
        justify-self: right;
        margin-right: 16px;
      }
    }

    .provedores-search {
      button {
        height: 40px;
        background: var(--secondary);
        border: 0px;
        color: white;

        img {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .incluir-provedor {
      button {
        height: 40px;
        background: var(--primary);
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        border: 0px;
        color: white;

        img {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .oportunidades {
      .title {
        width: calc(100% - 73px);
        height: 53px;
        background: #f5f5f5;
        font-size: 14px;

        img {
          width: 22px;
          height: 22px;
          margin: 8px;
        }
        a {
          text-decoration: none;
          color: #555555;
          font-family: 'Poppins-SemiBold';
        }
      }

      .count {
        background: #c4c4c4;
        display: flex;
        align-items: center;
        width: 73px;
        height: 53px;
        justify-content: center;

        .number {
          display: flex;
          background: #ffffff;
          border-radius: 10px;
          width: 57px;
          height: 37px;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .situacao {
      .title {
        width: calc(100% - 73px);
        height: 53px;
        background: #f5f5f5;
        font-size: 14px;

        color: #555555;
        font-family: 'Poppins-SemiBold';

        img {
          width: 22px;
          height: 22px;
          margin: 8px;
        }
      }

      .count {
        background: #c4c4c4;
        display: flex;
        align-items: center;
        width: 73px;
        height: 53px;
        justify-content: center;

        .number {
          display: flex;
          background: #ffffff;
          border-radius: 10px;
          width: 57px;
          height: 37px;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
