// @media (max-width: 1700px) {
//   .card-registro {
//     width: 746px !important;
//   }

// }

@media (max-width: 992px) {
  .card-registro {
    margin-top: 16px;
  }
}

@media (max-width: 992px) {
  .card-registro {
    width: 360px !important;

    .card-registro-body:first-child {
      width: inherit !important;
    }
    .card-registro-body:last-child {
      margin-top: 16px;
      width: 100% !important;
    }
  }
}

.card-registro {
  width: 746px;
  border: 20px solid var(--primary);
  border-radius: 20px;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.16);
  background-color: var(--primary);
  padding: 0 12px;

  .card-registro-body:first-child {
    width: calc(50% - 8px);
  }
  .card-registro-body:last-child {
    width: calc(50% - 8px);
  }

  .card-registro-body {
    font-family: var(--font-family);
    background-color: white;
    border-radius: 16px;
    height: 310px;
    padding: 16px 16px 115px 16px;

    .novo-registro {
     
      button {
        height: 40px;
        background: var(--primary);
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        border: 0px;
        color: white;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        img {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .xxxxx {
      .title {
        width: calc(100% - 73px);
        height: 53px;
        background: #f5f5f5;
        font-size: 14px;

        img {
          width: 22px;
          height: 22px;
          margin: 8px;
        }
      }

      .count {
        background: #c4c4c4;
        display: flex;
        align-items: center;
        width: 73px;
        height: 53px;
        justify-content: center;

        .number {
          display: flex;
          background: #ffffff;
          border-radius: 10px;
          width: 57px;
          height: 37px;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .situacao-title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 110%;
      color: var(--primary);
      max-width: 20ch;
      padding-left: 20px;

      img {
        width: 45px;
        height: 39px;
      }

      span {
        margin-left: 16px;
      }
    }
  }
}
